















































































import Vue from 'vue';
import AdminEmployees from '@/components/admin/Employees.vue';
import AdminOrganizations from '@/components/admin/Organizations.vue';
import AdminSchedulePaths from '@/components/admin/SchedulePaths.vue';

export default Vue.extend({
  components: {
    AdminEmployees,
    AdminOrganizations,
    AdminSchedulePaths,
  },
  data: () => ({
    screen: 'employees',
  }),
});
