

























































































































































































































































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import { Loader } from '@googlemaps/js-api-loader';
import Employee from '@/types/employee';
import { GETTING_ON_AND_OFF_TIME, MAP_DIR_URL } from '@/const/route';
import { Commute, CommuteType } from '@/types/commute';

export default Vue.extend({
  data: () => ({
    requestParam: {
      organizationId: null,
      date: null,
    },
    employeeList: {} as { [employeeId: string]: Employee },
    resultMap: {
      carpooled: '通勤した',
      absence: '欠勤',
      late: '遅刻',
      early_or_overtime: '早退 / 残業',
      other: 'その他',
    },
    dateMenu: false,
    requiredRules: [(v: any) => !!v || '入力してください'],
    expectedArrivalTimesToWork: [] as any[],
    expectedArrivalTimesFromWork: [] as any[],
  }),
  computed: {
    ...mapGetters('EmployeesEmployeeModule', ['findEmployeeById']),
    ...mapGetters('EmployeesOrganizationModule', ['organizations']),
    ...mapGetters('EmployeesCarpoolLogModule', [
      'todayCarpoolToWorkLog',
      'todayCarpoolFromWorkLog',
    ]),
  },
  methods: {
    ...mapActions('EmployeesCarpoolLogModule', ['getCarpoolLog']),
    refs(): any {
      return this.$refs;
    },
    showCarpoolLog() {
      if (!this.refs().requestForm.validate()) {
        return;
      }
      this.getCarpoolLog({
        organizationId: this.requestParam.organizationId,
        date: this.requestParam.date,
      });
    },
    initMap(
      serverId: string,
      clientIds: string[],
      afterDirectionRouted: any,
      kind: string
    ) {
      const server = this.employeeList[serverId];
      const clients: Employee[] = [];
      clientIds.forEach((clientId) => {
        clients.push(this.employeeList[clientId]);
      });

      let origin: any;
      let destination: any;
      let times: any;
      if (kind === 'to-work') {
        origin = {
          latitude: server.geoPoint?.latitude,
          longitude: server.geoPoint?.longitude,
        };
        destination = {
          latitude: server.workplaceGeoPoint?.latitude,
          longitude: server.workplaceGeoPoint?.longitude,
        };
        times = server.beginTimes;
      }
      if (kind === 'from-work') {
        origin = {
          latitude: server.workplaceGeoPoint?.latitude,
          longitude: server.workplaceGeoPoint?.longitude,
        };
        destination = {
          latitude: server.geoPoint?.latitude,
          longitude: server.geoPoint?.longitude,
        };
        times = server.finishTimes;
      }

      let departureTime = new Date();
      const now = new Date();
      const temp = new Date(
        '2021/04/01 ' + times[this.todayCarpoolToWorkLog.weekday]
      );
      departureTime.setHours(temp.getHours());
      departureTime.setMinutes(temp.getMinutes());
      if (departureTime < now) {
        departureTime = now;
      }

      let totalDuration = 0;
      const expectedArrivalTimes: any = [];

      const waypoints: any = [];
      clients.forEach((client: any) => {
        waypoints.push({
          location: {
            lat: client.geoPoint.latitude,
            lng: client.geoPoint.longitude,
          },
        });
      });
      const loader = new Loader({
        apiKey: JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG)['apiKey'],
        version: 'weekly',
      });
      loader.load().then(() => {
        new (window as any).google.maps.DirectionsService().route(
          {
            origin: {
              lat: origin.latitude,
              lng: origin.longitude,
            },
            destination: {
              lat: destination.latitude,
              lng: destination.longitude,
            },
            waypoints: waypoints,
            travelMode: (window as any).google.maps.TravelMode.DRIVING,
            drivingOptions: {
              departureTime: departureTime,
              trafficModel: (window as any).google.maps.TrafficModel.BEST_GUESS,
            },
          },
          (result: any, status: string) => {
            if (status == (window as any).google.maps.DirectionsStatus.OK) {
              result.routes[0].legs.forEach((leg: any) => {
                totalDuration += leg.duration.value + GETTING_ON_AND_OFF_TIME;
              });
              let serverTime;
              if (kind === 'to-work') {
                serverTime = DateTime.fromJSDate(
                  new Date(
                    '2021/04/01 ' + times[this.todayCarpoolToWorkLog.weekday]
                  )
                ).minus({
                  minutes: Math.round(totalDuration / 60),
                });
              }
              if (kind === 'from-work') {
                serverTime = DateTime.fromJSDate(
                  new Date(
                    '2021/04/01 ' + times[this.todayCarpoolToWorkLog.weekday]
                  )
                );
              }
              expectedArrivalTimes.push(serverTime);
              result.routes[0].legs.forEach((leg: any) => {
                expectedArrivalTimes.push(
                  expectedArrivalTimes[expectedArrivalTimes.length - 1].plus({
                    minutes: Math.round(
                      (leg.duration.value + GETTING_ON_AND_OFF_TIME) / 60
                    ),
                  })
                );
              });
            }
            afterDirectionRouted(expectedArrivalTimes);
          }
        );
      });
    },
    getMapDirUrl(
      serverId: string,
      clientIds: string[],
      commuteType: CommuteType
    ) {
      const server = this.employeeList[serverId];
      const clients: Employee[] = [];
      clientIds.forEach((clientId) => {
        clients.push(this.employeeList[clientId]);
      });
      let mapDirUrl = MAP_DIR_URL;
      let origin;
      let destination;
      const serverGeoPoint =
        (server.geoPoint || {}).latitude +
        ',' +
        (server.geoPoint || {}).longitude;
      const serverWorkspaceGeoPoint =
        (server.workplaceGeoPoint || {}).latitude +
        ',' +
        (server.workplaceGeoPoint || {}).longitude;
      if (commuteType === Commute.toWork) {
        origin = serverGeoPoint;
        destination = serverWorkspaceGeoPoint;
      } else if (commuteType === Commute.fromWork) {
        origin = serverWorkspaceGeoPoint;
        destination = serverGeoPoint;
      }
      mapDirUrl += '&origin=' + origin + '&waypoints=';
      clients.forEach((client: any) => {
        mapDirUrl +=
          client.geoPoint.latitude + ',' + client.geoPoint.longitude + '|';
      });
      mapDirUrl += '&destination=' + destination;
      return mapDirUrl;
    },
  },
  watch: {
    todayCarpoolToWorkLog() {
      this.employeeList = {};
      this.todayCarpoolToWorkLog.scheduleFeasiblePaths.forEach(
        (path: { server: string; clients: string[] }) => {
          this.employeeList[path.server] = this.findEmployeeById(path.server);
          path.clients.forEach((client) => {
            this.employeeList[client] = this.findEmployeeById(client);
          });
          this.initMap(
            path.server,
            path.clients,
            (expectedArrivalTimes: any) => {
              this.expectedArrivalTimesToWork.push(expectedArrivalTimes);
            },
            'to-work'
          );
        }
      );
    },
    todayCarpoolFromWorkLog() {
      this.todayCarpoolFromWorkLog.scheduleFeasiblePaths.forEach(
        (path: { server: string; clients: string[] }) => {
          this.initMap(
            path.server,
            path.clients,
            (expectedArrivalTimes: any) => {
              this.expectedArrivalTimesFromWork.push(expectedArrivalTimes);
            },
            'from-work'
          );
        }
      );
    },
  },
});
