




























import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    snackbar: {
      color: '',
      show: false,
      text: '',
      click: null,
    },
  }),
  methods: {
    showSnackbar(options: any) {
      options.show = true;
      this.snackbar = options;
    },
  },
});
