








































































































































































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import firebase from '@/firebase';
import Snackbar from '@/components/ui/Snackbar.vue';

export default Vue.extend({
  components: {
    Snackbar,
  },
  data: () => ({
    organizationSearch: '',
    organizationHeaders: [
      { text: '名称', value: 'name' },
      { text: '住所', value: 'location' },
      { text: '状態', value: 'activatedText', filterable: false },
      {
        text: '更新日',
        value: 'updatedAt',
        filterable: false,
        sortable: false,
      },
      { text: '', value: 'actions', filterable: false, sortable: false },
    ],
    organizationItems: [],
    organization: {
      id: '',
      name: '',
      activated: false,
      location: '',
      geoPoint: { latitude: 0, longitude: 0 },
    },
    newOrganization: {
      id: '',
      activated: true,
      name: '',
      location: '',
      geoPoint: { latitude: 0, longitude: 0 },
    },
    requiredRules: [(v: any) => !!v || '入力してください'],
    newOrganizationDialog: false,
    editOrganizationDialog: false,
    deleteOrganizationDialog: false,
  }),
  computed: {
    ...mapGetters('EmployeesOrganizationModule', ['organizations']),
  },
  methods: {
    ...mapActions('EmployeesOrganizationModule', [
      'listOrganizations',
      'createOrganization',
      'updateOrganization',
    ]),
    refs(): any {
      return this.$refs;
    },
    createOrganizationWithAny() {
      if (!this.refs().newOrganizationForm.validate()) {
        return;
      }
      console.log(this.newOrganization);
      this.createOrganization({
        activated: this.newOrganization.activated,
        fcmNotificationKey: 'FIX ME',
        location: this.newOrganization.location,
        geoPoint: new firebase.firestore.GeoPoint(
          this.newOrganization.geoPoint.latitude,
          this.newOrganization.geoPoint.longitude
        ),
        sameWorkplace: true,
        name: this.newOrganization.name,
      }).then((result) => {
        if (!result) {
          alert('組織を作成できませんでした。');
          return;
        }
        this.newOrganizationDialog = false;
        this.refs().snackbarRef.showSnackbar({
          color: 'success',
          text: '組織を作成しました。',
        });
        setTimeout(() => {
          location.reload();
        }, 3000);
      });
    },
    openEditOrganizationDialog(organization: any) {
      this.organization = organization;
      this.editOrganizationDialog = true;
    },
    editOrganization() {
      // TODO: 住所を変更したら、通勤者すべての住所も変更しないといけない気がする
      // if (!this.refs().editOrganizationForm.validate()) {
      //   return
      // }
      // this.updateOrganization({
      //   location: this.organization.location,
      //   geoPoint: new firebase.firestore.GeoPoint(this.organization.geoPoint.latitude, this.organization.geoPoint.longitude),
      //   name: this.organization.name,
      // })
      // .then(result => {
      //   if (!result) {
      //     alert('組織を編集できませんでした。')
      //     return
      //   }
      //   this.editOrganizationDialog = false
      //   this.refs().snackbarRef.showSnackbar({
      //     color: 'success',
      //     text: '組織を編集しました。'
      //   })
      //   location.reload()
      // })
    },
    openDeleteOrganizationDialog(organization: any) {
      this.organization = organization;
      this.deleteOrganizationDialog = true;
    },
    deleteOrganizationWithAll() {
      // TODO: 組織に属する通勤者全員を削除する
    },
  },
  created() {
    this.listOrganizations();
  },
  watch: {
    organizations() {
      const items: any = [];
      this.organizations.forEach((organization: any) => {
        items.push({
          id: organization.id,
          ref: organization.ref,
          activated: organization.activated,
          activatedText: organization.activated ? '有効' : '無効',
          name: organization.name,
          location: organization.location,
          geoPoint: organization.geoPoint,
          updatedAt: DateTime.fromJSDate(organization.updatedAt).toFormat(
            'yyyy.MM.dd'
          ),
        });
      });
      this.organizationItems = items;
    },
  },
});
