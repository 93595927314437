


















































































































































































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import Snackbar from '@/components/ui/Snackbar.vue';

export default Vue.extend({
  components: {
    Snackbar,
  },
  data: () => ({
    employeeSearch: '',
    employeeHeaders: [
      { text: 'メールアドレス', value: 'email' },
      { text: '表示名', value: 'displayName' },
      { text: '写真', value: 'photoURL', filterable: false, sortable: false },
      { text: '状態', value: 'activatedText', filterable: false },
      { text: '組織', value: 'organizationName' },
      { text: 'ログイン日', value: 'lastSignInTime', filterable: false },
      { text: '', value: 'actions', filterable: false, sortable: false },
    ],
    employeeItems: [],
    employee: {
      uid: '',
      ref: {},
      activated: false,
      displayName: '',
      email: '',
      organizationId: '',
      type: '',
    },
    newEmployee: {
      email: '',
      displayName: '',
      password: Math.random()
        .toString(32)
        .substring(2),
      organizationId: '',
      type: '',
    },
    emailRules: [
      (v: any) => !!v || 'メールアドレスを入力してください',
      (v: any) =>
        /.+@.+\..+/.test(v) || '有効なメールアドレスを入力してください',
    ],
    passwordRules: [(v: any) => !!v || 'パスワードを入力してください'],
    requiredRules: [(v: any) => !!v || '入力してください'],
    newEmployeeDialog: false,
    editEmployeeDialog: false,
    deleteEmployeeDialog: false,
  }),
  computed: {
    ...mapGetters('EmployeesEmployeeModule', ['findEmployeeById']),
    ...mapGetters('EmployeesOrganizationModule', [
      'organizations',
      'findOrganizationById',
    ]),
    ...mapGetters('EmployeesUserModule', ['users']),
  },
  methods: {
    ...mapActions('EmployeesEmployeeModule', [
      'listAllEmployees',
      'createEmployee',
      'updateEmployee',
      'deleteEmployee',
    ]),
    ...mapActions('EmployeesUserModule', [
      'listUsers',
      'createUser',
      'updateUser',
      'deleteUser',
    ]),
    ...mapActions('StorageProfilePhotoModule', ['deleteProfilePhoto']),
    refs(): any {
      return this.$refs;
    },
    createEmployeeWithEmailAndPassword() {
      if (!this.refs().newEmployeeForm.validate()) {
        return;
      }
      this.createUser(this.newEmployee).then((result) => {
        if (!result) {
          alert(
            '通勤者を追加できませんでした。エラー理由はコンソールログを確認してください。'
          );
        }
        setTimeout(() => {
          const user = this.users[this.users.length - 1];
          const organization = this.findOrganizationById(
            this.newEmployee.organizationId
          );
          this.createEmployee({
            id: user.uid,
            activated: false,
            beginTimes: {
              1: '09:00',
              2: '09:00',
              3: '09:00',
              4: '09:00',
              5: '09:00',
              6: null,
              7: null,
            },
            displayName: user.displayName,
            dropByWhenGoingHome: false,
            capacity: 0,
            email: user.email,
            finishTimes: {
              1: '18:00',
              2: '18:00',
              3: '18:00',
              4: '18:00',
              5: '18:00',
              6: null,
              7: null,
            },
            maxDrivingTime: 0,
            organizationRef: organization.ref,
            type: this.newEmployee.type,
            workplaceName: organization.name,
            workplaceLocation: organization.location,
            workplaceGeoPoint: organization.geoPoint,
          }).then(() => {
            this.newEmployeeDialog = false;
            this.refs().snackbarRef.showSnackbar({
              color: 'success',
              text: '通勤者を追加しました。',
            });
            setTimeout(() => {
              location.reload();
            }, 3000);
          });
        }, 100);
      });
    },
    openEditEmployeeDialog(employee: any) {
      this.employee = employee;
      this.editEmployeeDialog = true;
    },
    editEmployee() {
      if (!this.refs().editEmployeeForm.validate()) {
        return;
      }
      this.updateUser({
        uid: this.employee.uid,
        email: this.employee.email,
        displayName: this.employee.displayName,
      }).then((result) => {
        if (!result) {
          alert(
            '通勤者を編集できませんでした。エラー理由はコンソールログを確認してください。'
          );
          return;
        }
        setTimeout(() => {
          const organization = this.findOrganizationById(
            this.employee.organizationId
          );
          this.updateEmployee({
            ref: this.employee.ref,
            activated: this.employee.activated,
            displayName: this.employee.displayName,
            email: this.employee.email,
            organizationRef: organization.ref,
            type: this.employee.type,
            workplaceName: organization.name,
            workplaceLocation: organization.location,
            workplaceGeoPoint: organization.geoPoint,
          }).then(() => {
            this.editEmployeeDialog = false;
            this.refs().snackbarRef.showSnackbar({
              color: 'success',
              text: '通勤者を編集しました。',
            });
            location.reload();
          });
        }, 100);
      });
    },
    openDeleteEmployeeDialog(employee: any) {
      this.employee = employee;
      this.deleteEmployeeDialog = true;
    },
    deleteEmployeeWithAll() {
      this.deleteUser({
        uid: this.employee.uid,
      }).then((result) => {
        if (!result) {
          alert(
            '通勤者を削除できませんでした。エラー理由はコンソールログを確認してください。'
          );
          return;
        }
        this.deleteProfilePhoto({
          name: this.employee.uid,
        });
        this.deleteEmployee({
          ref: this.employee.ref,
        }).then(() => {
          this.deleteEmployeeDialog = false;
          this.refs().snackbarRef.showSnackbar({
            color: 'success',
            text:
              '通勤者を削除しました。削除したら運転経路を再計算してください。',
          });
          location.reload();
        });
      });
    },
  },
  created() {
    this.listAllEmployees().then(() => {
      this.listUsers();
    });
  },
  watch: {
    users() {
      const items: any = [];
      this.users.forEach((user: any) => {
        const item = Object.assign(
          {
            email: user.email,
            displayName: user.displayName || '',
            photoURL: user.photoURL || '',
            lastSignInTime: user.metadata.lastSignInTime
              ? DateTime.fromRFC2822(user.metadata.lastSignInTime).toFormat(
                  'yyyy.MM.dd'
                )
              : '',
            disabled: !user.disabled ? '有効' : '無効',
            uid: user.uid,
          },
          this.findEmployeeById(user.uid)
        );
        item.activatedText = item.activated ? '有効' : '無効';
        const organization = this.findOrganizationById(item.organizationRef.id);
        item.organizationId = item.organizationRef.id;
        item.organizationName = organization.name;
        items.push(item);
      });
      this.employeeItems = items;
    },
  },
});
